<template>
  <b-form @submit.prevent="signIn">
    <b-alert
      :show="error.show"
      dismissible variant="danger"
      @dismissed="error.show = false"
    >{{ error.message }}</b-alert>
    <b-form-group label="Email">
      <b-form-input
        v-model="email"
        type="email"
        placeholder="Enter email"
        required
      ></b-form-input>
    </b-form-group>
    <b-form-group label="Password">
      <b-form-input
        v-model="password"
        type="password"
        placeholder="Enter password"
        required
      ></b-form-input>
    </b-form-group>
    <b-button type="submit" variant="primary">Submit</b-button>
  </b-form>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data: () => ({
    email: '',
    password: '',
    error: {
      message: '',
      show: false,
    },
  }),
  methods: {
    ...mapActions({
      signInAction: 'auth/signIn',
    }),
    async signIn() {
      try {
        const { email, password } = this;
        await this.signInAction({ email, password });
      } catch (error) {
        this.error.message = error.message;
        this.error.show = true;
      }
    },
  },
};
</script>
