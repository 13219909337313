import { Auth } from 'aws-amplify';

export default {
  namespaced: true,
  state: {
    user: null,
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload;
    },
  },
  actions: {
    async signOut({ commit }) {
      commit('setUser', null);
      const resp = await Auth.signOut();
      return resp;
    },
    async signIn({ commit }, { email, password }) {
      try {
        await Auth.signIn(email, password);
        const userInfo = await Auth.currentUserInfo();
        commit('setUser', userInfo);
        return Promise.resolve();
      } catch (error) {
        console.log(error);
        return Promise.reject(error);
      }
    },
    async confirmSignup(_, { email, code }) {
      try {
        await Auth.confirmSignUp(email, code);
        return Promise.resolve();
      } catch (error) {
        console.log(error);
        return Promise.reject(error);
      }
    },
    async signUp(_, { email, password }) {
      try {
        await Auth.signUp({
          email,
          password,
          attributes: {
            email,
          },
        });
        return Promise.resolve();
      } catch (error) {
        console.log(error);
        return Promise.reject(error);
      }
    },
    async authAction({ commit }) {
      try {
        const userInfo = await Auth.currentUserInfo();
        commit('setUser', userInfo);
        return Promise.resolve();
      } catch (error) {
        console.log(error);
        return Promise.reject(error);
      }
    },
  },
  getters: {
    user: (state) => state.user,
  },
};
