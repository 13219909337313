<template>
  <b-container>
    <b-row>
      <b-col class="p-5">
        <b-card class="p-4">
          <sign-in/>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
// @ is an alias to /src
import SignIn from '@/components/SignIn.vue';

export default {
  name: 'Home',
  components: {
    SignIn,
  },
};
</script>
