/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cognito_identity_pool_id": "eu-west-2:614a98b6-cc70-43a3-8e3a-9931cf0e987c",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_NS2ZLNzkw",
    "aws_user_pools_web_client_id": "4rg4o21qqev5tds7jhj7tmd1kf",
    "oauth": {}
};


export default awsmobile;
